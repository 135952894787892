<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })">
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('image')"
                vid="icon"
                rules="required"
              >
                <ImagePreview
                  v-model="form.icon"
                  :title="$t('image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <hr class="mb-2">
            </b-col>
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right mt-2">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      form: {
        name: {
          en: null,
          ar: null,
        },
        icon: null,
        status: 'active',
      },
      isDataLoaded: false,
    }
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
    else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name_value,
            icon: data.icon_value,
            status: data.status,
          }
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.icon instanceof File)) {
          delete $form.icon
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },

}
</script>
